import create, { GetState, SetState } from "zustand";
import { persist } from "zustand/middleware";
import {
    BenchmarkEntry,
    BenchmarkRecording,
} from "../components/settings/Benchmark";
import {
    getCleanSettingsObject,
    Settings,
} from "../components/settings/Settings";
import { ThreeStatistic } from "../three/ThreeStats";
import { WebRTCStatistic } from "../webrtc/handleStatistics";

const webrtcStatistics = (set: SetState<object>, get: GetState<object>) => ({
    webrtcStatistics: [] as WebRTCStatistic[],
    addWebrtcStatistics: (item: WebRTCStatistic) =>
        set((state: any) => ({
            webrtcStatistics: [...state.webrtcStatistics, item],
        })),
});
const pingStatistics = (set: SetState<object>, get: GetState<object>) => ({
    pingStatistics: [] as number[],
    addPingStatistics: (item: number) =>
        set((state: any) => ({
            pingStatistics: [...state.pingStatistics, item],
        })),
});
const threeStatistics = (set: SetState<object>, get: GetState<object>) => ({
    threeStatistics: [] as ThreeStatistic[],
    addThreeStatistics: (item: ThreeStatistic) =>
        set((state: any) => ({
            threeStatistics: [...state.threeStatistics, item],
        })),
});
const isPointerLocked = (set: SetState<object>, get: GetState<object>) => ({
    isPointerLocked: false,
    setIsPointerLocked: (item: boolean) =>
        set((state: any) => ({
            isPointerLocked: item,
        })),
    toggleIsPointerLocked: () =>
        set((state: any) => ({
            isPointerLocked: !state.isPointerLocked,
        })),
});
const benchmark = (set: SetState<object>, get: GetState<object>) => ({
    benchmark: null as BenchmarkRecording | null,
    setBenchmark: (item: BenchmarkRecording) =>
        set((state: any) => ({
            benchmark: item,
        })),
});
const isPerformaceMenuOpen = (
    set: SetState<object>,
    get: GetState<object>
) => ({
    isPerformaceMenuOpen: false,
    setIsPerformaceMenuOpen: (item: boolean) =>
        set((state: any) => ({
            isPerformaceMenuOpen: item,
        })),
    toggleIsPerformaceMenuOpen: () =>
        set((state: any) => ({
            isPerformaceMenuOpen: !state.isPerformaceMenuOpen,
        })),
});
const settings = (set: SetState<object>, get: GetState<object>) => ({
    settings: getCleanSettingsObject() as Settings,
    setSettings: (item: Settings) =>
        set((state: any) => ({
            settings: item,
        })),
    setSettingsAttribute: (category: string, property: string, value: any) =>
        set((state: any) => ({
            settings: {
                ...state.settings,
                [category]: {
                    ...state.settings[category],
                    [property]: value,
                },
            },
        })),
});
const getCleanStore = (set: SetState<object>, get: GetState<object>) => ({
    ...webrtcStatistics(set, get),
    ...threeStatistics(set, get),
    ...pingStatistics(set, get),
    ...isPointerLocked(set, get),
    ...isPerformaceMenuOpen(set, get),
    ...settings(set, get),
    ...benchmark(set, get),
    deleteEverything: () => {
        localStorage.removeItem("zustand");
        set(getCleanStore(set, get), true);
    },
    _hasHydrated: false,
});
export const useStore = create(
    persist((set: any, get: any) => getCleanStore(set, get), {
        name: "zustand/360WebRTCRenderer", // unique name
        getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
        onRehydrateStorage: () => () => {
            useStore.setState({ _hasHydrated: true });
        },
        version: 5,
        partialize: (state) =>
            Object.fromEntries(
                Object.entries(state).filter(
                    ([key]) =>
                        ![
                            "webrtcStatistics",
                            "threeStatistics",
                            "pingStatistics",
                            "isPointerLocked",
                        ].includes(key)
                )
            ),
    })
);
