import { unstable_batchedUpdates } from "react-dom";
import { useStore } from "../utils/zustand";
import { MessageType, send } from "./handleInputs";

let statisticInterval = 0;
let pingInterval = 0;

let lastReport: any = {};

export interface WebRTCStatistic {
    framesDecoded: number;
    avgDecodingTime: number;
    avgProcessingTime: number;
    bitrate: number;
    fps: number;
}

export const handleStatistics = (rtcConnection: RTCPeerConnection) => {
    //Cleanup old functions
    window.clearInterval(statisticInterval);
    window.clearInterval(pingInterval);

    statisticInterval = window.setInterval(() => {
        rtcConnection.getStats(null).then((stats) => {
            stats.forEach((rtcreport: RTCStats, key) => {
                if (rtcreport.type === "inbound-rtp") {
                    let report: RTCInboundRtpStreamStats =
                        rtcreport as RTCInboundRtpStreamStats;
                    const last = lastReport[key];

                    if (last) {
                        const framesDecoded =
                            (report as any).framesDecoded - last?.framesDecoded;
                        const avgDecodingTime =
                            (((report as any).totalDecodeTime -
                                last?.totalDecodeTime) /
                                framesDecoded) *
                            1000;
                        const avgProcessingTime =
                            (((report as any).totalProcessingDelay -
                                last?.totalProcessingDelay) /
                                framesDecoded) *
                            1000;
                        const bitrate = Math.round(
                            (((report as any).bytesReceived -
                                last?.bytesReceived) *
                                8) /
                                1024
                        );
                        const fps =
                            (report as any).framesReceived -
                            lastReport[key]?.framesReceived;

                        unstable_batchedUpdates(() => {
                            useStore.getState().addWebrtcStatistics({
                                fps,
                                avgDecodingTime,
                                avgProcessingTime,
                                bitrate,
                                framesDecoded,
                            });
                        });
                    }
                    lastReport[key] = rtcreport;
                }
            });
        });
    }, 1000);

    pingInterval = window.setInterval(() => {
        const currentTime = performance.now();
        const packet = new Float64Array(1);
        packet[0] = currentTime;

        send(MessageType.PING, packet);
    }, 1000);
};
