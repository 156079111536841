export const getDefaultURL = () => {
    let websocketUrl;
    if (
        window.location.hostname.includes("localhost") ||
        window.location.hostname.startsWith("192.168") ||
        window.location.hostname.includes("127.0.0.1")
    ) {
        if (window.location.protocol === "https:") {
            websocketUrl = "wss://" + window.location.hostname + ":3001";
        } else {
            websocketUrl = "ws://" + window.location.hostname + ":3001";
        }
    } else {
        if (window.location.protocol === "https:") {
            websocketUrl = "wss://" + window.location.hostname;
        } else {
            websocketUrl = "ws://" + window.location.hostname;
        }
    }
    return websocketUrl;
};

export class WebSocketSignaling extends EventTarget {
    websocket: WebSocket | null = null;
    connectionId?: string | null;
    isWsOpen?: boolean;
    location: string;
    sleep: (msec: number) => Promise<void>;

    constructor(signalingServer?: string) {
        super();
        this.sleep = (msec) =>
            new Promise((resolve) => setTimeout(resolve, msec));

        let websocketUrl = signalingServer;
        if (!websocketUrl) {
            websocketUrl = getDefaultURL();
        }
        this.location = websocketUrl;
        this.createNewWebSocket(websocketUrl, true);
    }
    createNewWebSocket(newLocation?: string, force?: boolean) {
        if (!newLocation) newLocation = getDefaultURL();
        console.log("websocket location", newLocation, this.location);

        if (newLocation !== this.location || force) {
            this.websocket = new WebSocket(newLocation);

            this.connectionId = null;

            console.log(this.websocket);
            this.websocket.onopen = () => {
                this.isWsOpen = true;
            };

            this.websocket.onclose = () => {
                this.isWsOpen = false;
            };

            this.websocket.onmessage = (event) => {
                const msg = JSON.parse(event.data);
                if (!msg || !this) {
                    return;
                }

                console.log("%cwebsocket message", "color: green", msg.type);

                switch (msg.type) {
                    case "connect":
                        this.dispatchEvent(
                            new CustomEvent("connect", { detail: msg })
                        );
                        break;
                    case "disconnect":
                        this.dispatchEvent(
                            new CustomEvent("disconnect", { detail: msg })
                        );
                        break;
                    case "offer":
                        this.dispatchEvent(
                            new CustomEvent("offer", {
                                detail: {
                                    connectionId: msg.from,
                                    sdp: msg.data.sdp,
                                    polite: msg.data.polite,
                                },
                            })
                        );
                        break;
                    case "answer":
                        this.dispatchEvent(
                            new CustomEvent("answer", {
                                detail: {
                                    connectionId: msg.from,
                                    sdp: msg.data.sdp,
                                },
                            })
                        );
                        break;
                    case "candidate":
                        this.dispatchEvent(
                            new CustomEvent("candidate", {
                                detail: {
                                    connectionId: msg.from,
                                    candidate: msg.data.candidate,
                                    sdpMLineIndex: msg.data.sdpMLineIndex,
                                    sdpMid: msg.data.sdpMid,
                                },
                            })
                        );
                        break;
                    default:
                        break;
                }
            };
        }
    }
    send(message: any) {
        const that = this;
        this.waitForConnection(function () {
            that.websocket?.send(message);
        }, 1000);
    }

    waitForConnection(callback: () => void, interval: number) {
        if (this.websocket?.readyState === 1) {
            callback();
        } else {
            var that = this;
            // optional: implement backoff for interval here
            setTimeout(function () {
                that.waitForConnection(callback, interval);
            }, interval);
        }
    }
    get interval() {
        return 100;
    }

    async start() {
        while (!this.isWsOpen) {
            await this.sleep(100);
        }
    }

    async stop() {
        this.websocket?.close();
        while (this.isWsOpen) {
            await this.sleep(100);
        }
    }

    createConnection(connectionId: string) {
        const sendJson = JSON.stringify({
            type: "connect",
            connectionId: connectionId,
        });
        this.send(sendJson);
    }

    deleteConnection(connectionId: string) {
        const sendJson = JSON.stringify({
            type: "disconnect",
            connectionId: connectionId,
        });
        this.send(sendJson);
    }

    sendOffer(connectionId: string, sdp: string) {
        const data = { sdp: sdp, connectionId: connectionId };
        const sendJson = JSON.stringify({
            type: "offer",
            from: connectionId,
            data: data,
        });
        this.send(sendJson);
    }

    sendAnswer(connectionId: string, sdp: string) {
        const data = { sdp: sdp, connectionId: connectionId };
        const sendJson = JSON.stringify({
            type: "answer",
            from: connectionId,
            data: data,
        });
        this.send(sendJson);
    }

    sendCandidate(
        connectionId: string,
        candidate: any,
        sdpMLineIndex: number,
        sdpMid: any
    ) {
        const data = {
            candidate: candidate,
            sdpMLineIndex: sdpMLineIndex,
            sdpMid: sdpMid,
            connectionId: connectionId,
        };
        const sendJson = JSON.stringify({
            type: "candidate",
            from: connectionId,
            data: data,
        });
        this.send(sendJson);
    }
}
