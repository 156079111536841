import { unstable_batchedUpdates } from "react-dom";
import { useStore } from "../utils/zustand";
import { cleanupInputHandler, handleInput, MessageType } from "./handleInputs";
import { handleStatistics } from "./handleStatistics";
import { WebSocketSignaling } from "./WebSocketSignaling";

let websocket: WebSocketSignaling;
let rtcPeerConnection: RTCPeerConnection;
let connectionId: string;
let inputSenderChannel: RTCDataChannel;

let localStream: MediaStream;
export const initWebRtc = async (signalingServer?: string) => {
    //init rtcpeerconnection
    console.log("init");
    if (websocket) {
        websocket.deleteConnection(connectionId);
        await websocket.stop();
        cleanupInputHandler();
        rtcPeerConnection?.close();
    }
    connectionId = uuid4();
    websocket = new WebSocketSignaling(signalingServer);
    rtcPeerConnection = new RTCPeerConnection(getRTCConfiguration());

    localStream = new MediaStream();
    const videoPlayer = document.getElementsByTagName("video")[0];
    videoPlayer.srcObject = localStream;
    //Create the offer
    //To save some sanity: This will get called automaticly by the RTCPeerConnection class as sppn as a canidate is available
    rtcPeerConnection.onicecandidate = ({ candidate }) => {
        console.log("onicecandidate");

        if (!candidate || !connectionId) {
            return;
        }
        websocket.sendCandidate(
            connectionId,
            candidate.candidate,
            candidate.sdpMLineIndex ?? 0,
            candidate.sdpMid
        );
    };
    rtcPeerConnection.onnegotiationneeded = async () => {
        console.log("onnegotiationneeded");

        try {
            await rtcPeerConnection.setLocalDescription(
                await rtcPeerConnection.createOffer()
            );
            // send the offer to the other peer
            //dispatch a event to send the offer to unity
            websocket.sendOffer(
                connectionId,
                rtcPeerConnection.localDescription?.sdp!
            );
        } catch (err) {
            console.error(err);
        }
    };
    rtcPeerConnection.oniceconnectionstatechange = (e) => {
        console.log(
            `iceConnectionState changed:`,
            rtcPeerConnection.iceConnectionState
        );
        if (rtcPeerConnection.iceConnectionState === "disconnected") {
            //TODO cleanup on disconnect
        }
    };

    //wait for an answer from unity
    websocket.addEventListener("answer", async (e: any) => {
        const answer = e.detail;
        const desc = new RTCSessionDescription({
            sdp: answer.sdp,
            type: "answer",
        });
        console.log("%canswered!", "color: green");
        if (rtcPeerConnection && answer.connectionId === connectionId) {
            //set the received answer in the rtcPeerConnection
            await rtcPeerConnection.setRemoteDescription(desc);
            console.log("%canswered!", "color: green");

            rtcPeerConnection.dispatchEvent(new Event("negotiated"));
        }
    });

    //This will get called when the a canidate is reveiced
    websocket.addEventListener("candidate", async (e: any) => {
        const candidate = e.detail;
        const iceCandidate = new RTCIceCandidate({
            candidate: candidate.candidate,
            sdpMid: candidate.sdpMid,
            sdpMLineIndex: candidate.sdpMLineIndex,
        });
        if (rtcPeerConnection && candidate.connectionId === connectionId) {
            try {
                await rtcPeerConnection.addIceCandidate(iceCandidate);
                console.log("%ccandidate accepted", "color: lightblue");
            } catch (e) {
                //console.error("candidate reject", e);
            }
        }
    });
    websocket.addEventListener("offer", async (e: any) => {
        const offer = e.detail;
        const desc = new RTCSessionDescription({
            sdp: offer.sdp,
            type: "offer",
        });
        if (rtcPeerConnection) {
            await rtcPeerConnection.setRemoteDescription(desc);
            await rtcPeerConnection.setLocalDescription();
            websocket.sendAnswer(
                connectionId,
                rtcPeerConnection.localDescription?.sdp!
            );

            rtcPeerConnection.dispatchEvent(new Event("negotiated"));
        }
    });
    rtcPeerConnection.ontrack = (data: any) => {
        console.log("trackevent %c" + data.track.kind, "color: red");

        if (data.track.kind === "video") {
            localStream.addTrack(data.track);
            videoPlayer.play();
        }
        if (data.track.kind === "audio") {
            localStream.addTrack(data.track);
        }
    };

    inputSenderChannel = rtcPeerConnection.createDataChannel("input");

    inputSenderChannel.onmessage = (event) => {
        const now = performance.now();
        const type = new DataView(event.data).getUint16(0, true);
        if (type === MessageType.PING) {
            const startTime = new DataView(event.data).getFloat64(2, true);
            const delta = now - startTime;

            unstable_batchedUpdates(() => {
                useStore
                    .getState()
                    .addPingStatistics(Math.round(delta * 10) / 10);
            });
        }
    };

    handleInput(rtcPeerConnection, inputSenderChannel);

    handleStatistics(rtcPeerConnection);
};

function uuid4() {
    var temp_url = URL.createObjectURL(new Blob());
    var uuid = temp_url.toString();
    URL.revokeObjectURL(temp_url);
    return uuid.split(/[:/]/g).pop()?.toLowerCase() ?? ""; // remove prefixes
}
function getRTCConfiguration() {
    let config: any = {};
    config.sdpSemantics = "unified-plan";
    config.iceServers = [{ urls: ["stun:stun.l.google.com:19302"] }];
    return config;
}
