import { Line } from "react-chartjs-2";

interface PlotChartProps {
    data: number[];
    label: string;
}

const PlotChart = ({ data, label }: PlotChartProps) => {
    const dataObject = {
        labels: data.map((item, index) => data.length - index + " seconds ago"),
        datasets: [
            {
                id: 1,
                label: "Test",
                borderColor: "rgb(31, 111, 231)",
                backgroundColor: "rgb(31, 111, 231,0.5)",

                data: data,
            },
        ],
    };
    return (
        <Line
            options={{
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: label,
                        color: "#fff",
                    },
                },
                scales: {
                    xAxes: {
                        display: false,
                    },
                    yAxes: {
                        ticks: {
                            color: "#fff",
                        },
                        beginAtZero: true,
                    },
                },
                animation: {
                    duration: 0,
                },
                elements: {
                    point: {
                        radius: 0,
                    },
                },
            }}
            data={dataObject}
        />
    );
};
export default PlotChart;
