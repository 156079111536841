import { useCallback, useEffect, useState } from "react";
import { useStore } from "../../utils/zustand";
import { KeyboardEvents, MessageType } from "../../webrtc/handleInputs";

export type MousePayload = {
    x: number;
    y: number;
};
export type KeyboardPayload = {
    type: KeyboardEvents;
    keycode: number;
};
export type BenchmarkEntry = {
    data: MousePayload | KeyboardPayload;
    delay: number;
    type: MessageType;
};

export type BenchmarkRecording = BenchmarkEntry[];

let lastTimeStamp = performance.now();
let benchmarkRecording: BenchmarkRecording = [];

const Benchmark = () => {
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [isBenchmarkRunning, setIsBenchmarkRunning] =
        useState<boolean>(false);
    const benchmark = useStore((state) => state.benchmark);
    const isPointerLocked = useStore((state) => state.isPointerLocked);
    const setBenchmark = useStore((state) => state.setBenchmark);
    const changeSettings = useStore((state) => state.setSettingsAttribute);
    const showInputOverlay = useStore(
        (state) => state.settings.overlay.showInputOverlay
    );

    const runBenchmark = (index: number) => {
        const item = benchmark?.[index];
        if (item) {
            window.setTimeout(() => {
                if (item.type === MessageType.MouseEvent) {
                    const event = new CustomEvent<MousePayload>(
                        "benchmarkMouseMovement",
                        { detail: item.data as MousePayload }
                    );
                    document.dispatchEvent(event);
                } else if (item.type === MessageType.KeyboardEvent) {
                    const event = new CustomEvent<KeyboardPayload>(
                        "benchmarkKeyboard",
                        { detail: item.data as KeyboardPayload }
                    );
                    document.dispatchEvent(event);
                }
                runBenchmark(index + 1);
            }, item.delay);
        } else {
            setIsBenchmarkRunning(false);
        }
    };

    const onDocumentMouseMove = useCallback(
        (event: MouseEvent) => {
            if (isPointerLocked) {
                const movementX = event.movementX || 0;
                const movementY = event.movementY || 0;
                const now = performance.now();
                benchmarkRecording.push({
                    data: {
                        x: movementX,
                        y: movementY,
                    },
                    delay: Math.round(now - lastTimeStamp),
                    type: MessageType.MouseEvent,
                });
                lastTimeStamp = now;
                //
            }
        },
        [isPointerLocked]
    );
    const handleKeyEvents = useCallback((event: KeyboardEvent) => {
        const key = event.keyCode;
        const now = performance.now();
        if (event.type === "keydown") {
            if (!event.repeat) {
                benchmarkRecording.push({
                    data: {
                        type: KeyboardEvents.KeyDown,
                        keycode: key,
                    },
                    delay: Math.round(now - lastTimeStamp),
                    type: MessageType.KeyboardEvent,
                });
                lastTimeStamp = now;
            }
            // TextEvent
        } else if (event.type === "keyup") {
            benchmarkRecording.push({
                data: {
                    type: KeyboardEvents.KeyUp,
                    keycode: key,
                },
                delay: Math.round(now - lastTimeStamp),
                type: MessageType.KeyboardEvent,
            });
            lastTimeStamp = now;
        }
    }, []);
    useEffect(() => {
        document.addEventListener("mousemove", onDocumentMouseMove, false);

        document.addEventListener("keyup", handleKeyEvents, false);
        document.addEventListener("keydown", handleKeyEvents, false);
        return () => {
            document.removeEventListener(
                "mousemove",
                onDocumentMouseMove,
                false
            );
            document.removeEventListener("keyup", handleKeyEvents, false);
            document.removeEventListener("keydown", handleKeyEvents, false);
        };
    }, [onDocumentMouseMove]);
    return (
        <div className="connectionSettings">
            <h2>Benchmark</h2>
            <div>
                <button
                    onClick={() => {
                        lastTimeStamp = performance.now();

                        if (isRecording) {
                            setBenchmark(benchmarkRecording);
                        } else {
                            benchmarkRecording = [];
                        }
                        setIsRecording((old) => !old);
                    }}
                >
                    {isRecording ? "Stop Recording" : "Start Recording"}
                </button>
            </div>
            <div>
                <button
                    disabled={isBenchmarkRunning}
                    onClick={() => {
                        setIsBenchmarkRunning(true);
                        runBenchmark(0);
                    }}
                >
                    Run Benchmark
                </button>
            </div>
            <div>
                <div>
                    <input
                        type="checkbox"
                        id="showInputOverlay"
                        onChange={(e) =>
                            changeSettings(
                                "overlay",
                                "showInputOverlay",
                                e.target.checked
                            )
                        }
                        checked={showInputOverlay}
                    />
                    <label htmlFor="showInputOverlay">Show Input Overlay</label>
                </div>
            </div>
        </div>
    );
};
export default Benchmark;
