import { useStore } from "../../utils/zustand";
import {
    PlayerSwitchEvent,
    sendPlayerSwitchEvent,
} from "../../webrtc/handleInputs";

const PlayerSettings = () => {
    const settings = useStore((state) => state.settings.videoplayer);
    const changeSettings = useStore((state) => state.setSettingsAttribute);
    const togglePlayer = (e: React.ChangeEvent<HTMLInputElement>) => {
        changeSettings("videoplayer", "isPlayer3d", e.target.checked);
    };
    const toggleUnityMode = (e: React.ChangeEvent<HTMLInputElement>) => {
        changeSettings("videoplayer", "unityMode", e.target.checked);
        sendPlayerSwitchEvent(
            e.target.checked
                ? PlayerSwitchEvent.ThreeJS
                : PlayerSwitchEvent.Normal
        );
    };
    return (
        <div className="connectionSettings">
            <h2>Videoplayer</h2>
            <div>
                <div>
                    <input
                        type="checkbox"
                        id="isPlayer3d"
                        onChange={togglePlayer}
                        checked={settings.isPlayer3d}
                    />
                    <label htmlFor="isPlayer3d">3D Player</label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="unityMode"
                        onChange={toggleUnityMode}
                        checked={settings.unityMode}
                    />
                    <label htmlFor="unityMode">Is Unity redering 360°</label>
                </div>
                <button
                    onClick={(e) =>
                        document.getElementsByTagName("video")[0]?.play()
                    }
                >
                    Press play on Videoplayer
                </button>
            </div>
        </div>
    );
};
export default PlayerSettings;
