import { useStore } from "../utils/zustand";
import PlotChart from "./PlotChart";

const Statistics = () => {
    const pingStatistic = useStore((state) => state.pingStatistics);
    const webrtcStatistics = useStore((state) => state.webrtcStatistics);
    const threeStatistics = useStore((state) => state.threeStatistics);
    const settings = useStore((state) => state.settings.statistics);

    return (
        <div className="statistics">
            <div className="charts">
                {settings.ping && (
                    <PlotChart
                        label="Ping (ms)"
                        data={pingStatistic.slice(-120)}
                    />
                )}
                {settings.bitrate && (
                    <PlotChart
                        label="Bitrate (kbit/s)"
                        data={webrtcStatistics
                            .slice(-120)
                            .map((item) => item.bitrate)}
                    />
                )}
                {settings.averageDecodingTime && (
                    <PlotChart
                        label="Average Decoding Time (ms)"
                        data={webrtcStatistics
                            .slice(-120)
                            .map((item) => item.avgDecodingTime)}
                    />
                )}
                {settings.averageProcessingTime && (
                    <PlotChart
                        label="Average Processing Time (ms)"
                        data={webrtcStatistics
                            .slice(-120)
                            .map((item) => item.avgProcessingTime)}
                    />
                )}
                {settings.fpsStream && (
                    <PlotChart
                        label="FPS (Stream)"
                        data={webrtcStatistics
                            .slice(-120)
                            .map((item) => item.fps)}
                    />
                )}
                {settings.decodedFps && (
                    <PlotChart
                        label="Decoded Frames per Second"
                        data={webrtcStatistics
                            .slice(-120)
                            .map((item) => item.framesDecoded)}
                    />
                )}
                {settings.fpsBrowser && (
                    <PlotChart
                        label="FPS (Browser)"
                        data={threeStatistics
                            .slice(-120)
                            .map((item) => item.fps)}
                    />
                )}
                {settings.avgFrametimeBrowser && (
                    <PlotChart
                        label="Average Frametime (ms) (Browser)"
                        data={threeStatistics
                            .slice(-120)
                            .map((item) => item.avgFrameTimes)}
                    />
                )}
                {settings.maxFrametimeBrowser && (
                    <PlotChart
                        label="Maximum Frametime (ms) (Browser)"
                        data={threeStatistics
                            .slice(-120)
                            .map((item) => item.maxFrameTimes)}
                    />
                )}
            </div>
            <div className="hint">
                <p>Press P to toggle performace monitoring</p>
            </div>
        </div>
    );
};

export default Statistics;
