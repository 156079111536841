import { getDefaultURL } from "../../webrtc/WebSocketSignaling";
import Benchmark from "./Benchmark";
import ConnectionSettings from "./ConnectionSettings";
import PlayerSettings from "./PlayerSettings";
import StatisticsSettings from "./StatisticsSettings";

export interface Settings {
    statistics: {
        bitrate: boolean;
        averageDecodingTime: boolean;
        averageProcessingTime: boolean;
        fpsStream: boolean;
        decodedFps: boolean;
        fpsBrowser: boolean;
        avgFrametimeBrowser: boolean;
        maxFrametimeBrowser: boolean;
        ping: boolean;
    };
    connection: {
        signalingUrl: string;
    };
    videoplayer: {
        isPlayer3d: boolean;
        unityMode: boolean;
    };
    overlay: {
        showInputOverlay: boolean;
    };
}
export const getCleanSettingsObject = (): Settings => ({
    statistics: {
        bitrate: true,
        averageDecodingTime: true,
        averageProcessingTime: true,
        fpsStream: true,
        decodedFps: true,
        fpsBrowser: true,
        avgFrametimeBrowser: true,
        maxFrametimeBrowser: true,
        ping: true,
    },
    connection: {
        signalingUrl: getDefaultURL(),
    },
    videoplayer: {
        isPlayer3d: true,
        unityMode: true,
    },
    overlay: {
        showInputOverlay: true,
    },
});
const SettingsComponent = ({ hidden }: { hidden: boolean }) => {
    return (
        <div
            className="settings"
            style={{ display: !hidden ? "none" : "block" }}
        >
            <h1>Settings</h1>
            <StatisticsSettings />
            <ConnectionSettings />
            <PlayerSettings />
            <Benchmark />
        </div>
    );
};
export default SettingsComponent;
