import { useFrame } from "@react-three/fiber";
import { useEffect } from "react";
import { useStore } from "../utils/zustand";

export interface ThreeStatistic {
    avgFrameTimes: number;
    maxFrameTimes: number;
    fps: number;
}
let frametimes: number[] = [];

const ThreeStats = () => {
    const addThreeStat = useStore((state) => state.addThreeStatistics);

    useFrame((state, delta) => {
        frametimes.push(delta);
    });
    useEffect(() => {
        const intervalId = window.setInterval(() => {
            addThreeStat({
                fps: frametimes.length,
                avgFrameTimes:
                    (frametimes.reduce((a, b) => a + b, 0) /
                        frametimes.length || 0) * 1000,
                maxFrameTimes: Math.max(...frametimes) * 1000,
            });
            frametimes = [];
            // console.log(frametimes);
        }, 1000);

        return () => window.clearInterval(intervalId);
    }, []);
    return <></>;
};
export default ThreeStats;
