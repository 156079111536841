import { KeyboardPayload } from "../components/settings/Benchmark";

export enum MessageType {
    Connect = 0,
    Disconnect = 1,
    KeyboardEvent = 2,
    RotationEvent = 3,
    MouseEvent = 4,
    PlayerSwitchEvent = 5,
    PING = 6,
}
export enum KeyboardEvents {
    KeyUp = 0,
    KeyDown = 1,
}
export enum MouseEvents {
    KeyUp = 0,
    KeyDown = 1,
}
export enum PlayerSwitchEvent {
    ThreeJS = 0,
    Normal = 1,
}
let inputChannel: RTCDataChannel;
export const handleInput = (
    rtc: RTCPeerConnection,
    inputChannelNew: RTCDataChannel
) => {
    inputChannel = inputChannelNew;
    document.addEventListener("keyup", handleKeyEvents, false);
    document.addEventListener("keydown", handleKeyEvents, false);
    document.addEventListener("mousedown", handleMouseEvents, false);
    document.addEventListener("mouseup", handleMouseEvents, false);
    window.addEventListener(
        "contextmenu",
        function (e) {
            e.preventDefault();
        },
        false
    );
    document.addEventListener(
        "benchmarkKeyboard",
        handleBenchmarkKeyEvenet,
        false
    );
};
export const cleanupInputHandler = () => {
    document.removeEventListener("keyup", handleKeyEvents, false);
    document.removeEventListener("keydown", handleKeyEvents, false);
    document.removeEventListener("mousedown", handleMouseEvents, false);
    document.removeEventListener("mouseup", handleMouseEvents, false);
    window.removeEventListener(
        "contextmenu",
        function (e) {
            e.preventDefault();
        },
        false
    );
    document.removeEventListener(
        "benchmarkKeyboard",
        handleBenchmarkKeyEvenet,
        false
    );
};
const handleBenchmarkKeyEvenet = (event: any) => {
    const ev = event as CustomEvent<KeyboardPayload>;
    const key = ev.detail.keycode;
    if (ev.detail.type === KeyboardEvents.KeyDown) {
        if (!event.repeat) {
            sendKeyBoardEvent(KeyboardEvents.KeyDown, key);
        }
        // TextEvent
    } else if (ev.detail.type === KeyboardEvents.KeyUp) {
        sendKeyBoardEvent(KeyboardEvents.KeyUp, key);
    }
};
const handleMouseEvents = (event: MouseEvent) => {
    if (event.type === "mousedown") {
        sendMouseEvent(MouseEvents.KeyDown, event.button);
    } else if (event.type === "mousedown") {
        sendMouseEvent(MouseEvents.KeyUp, event.button);
    }
    event.preventDefault();
    return false;
};
const handleKeyEvents = (event: KeyboardEvent) => {
    const key = event.keyCode;
    if (event.type === "keydown") {
        if (!event.repeat) {
            sendKeyBoardEvent(KeyboardEvents.KeyDown, key);
        }
        // TextEvent
    } else if (event.type === "keyup") {
        sendKeyBoardEvent(KeyboardEvents.KeyUp, key);
    }
};

export const handleCameraRotation = (lat: number, lon: number) => {
    const packet = new Float64Array(2);
    packet[0] = lat;
    packet[1] = lon;

    // packet.BYTES_PER_ELEMENT;
    send(MessageType.RotationEvent, packet);
};
const sendMouseEvent = (mouseEventType: MouseEvents, mouseKeyCode: number) => {
    const packet = new Uint16Array(2);
    packet[0] = mouseEventType;
    packet[1] = mouseKeyCode;
    send(MessageType.MouseEvent, packet);
};
const sendKeyBoardEvent = (
    keyboardEventType: KeyboardEvents,
    keyCode: number
) => {
    const packet = new Uint16Array(2);
    packet[0] = keyboardEventType;
    packet[1] = keyCode;

    send(MessageType.KeyboardEvent, packet);
};
export const sendPlayerSwitchEvent = (
    playerSwitchEventType: PlayerSwitchEvent
) => {
    const packet = new Uint16Array(1);
    packet[0] = playerSwitchEventType;
    send(MessageType.PlayerSwitchEvent, packet);
};
export const send = (type: MessageType, messageBuffer: ArrayBuffer) => {
    let messageArray = new Uint8Array((messageBuffer as any).buffer);
    //Bodysize + headersize
    const packet = new ArrayBuffer(messageArray.byteLength + 2);

    //mesageHeader
    let dataView = new DataView(packet);
    dataView.setUint16(0, type, true);

    //messageBody
    let uint8view = new Uint8Array(packet);
    uint8view.set(messageArray, 2);

    inputChannel.send(packet);
};
