import "./styles/index.css";

import { useEffect, useState } from "react";
import DDDPlayer from "./three/3DPlayer";
import { initWebRtc } from "./webrtc/init";
import { useStore } from "./utils/zustand";
import Statistics from "./components/Statistics";
import { Chart as ChartJS, registerables } from "chart.js";
import Settings from "./components/settings/Settings";
import VideoPlayer from "./three/VideoPlayer";
import InputOverlay from "./components/InputOverlay";

const App = () => {
    const isPointerLocked = useStore((state) => state.isPointerLocked);
    const signalingUrl = useStore(
        (state) => state.settings.connection.signalingUrl
    );
    const isPlayer3d = useStore(
        (state) => state.settings.videoplayer.isPlayer3d
    );
    const showInputOverlay = useStore(
        (state) => state.settings.overlay.showInputOverlay
    );
    const [isPerformaceMenuOpen, toggleIsPerformaceMenuOpen] = useStore(
        (state) => [
            state.isPerformaceMenuOpen,
            state.toggleIsPerformaceMenuOpen,
        ]
    );
    const [hasWebRTCStarted, setHasWebRTCStarted] = useState<boolean>(false);

    const startWebRTC = async () => {
        initWebRtc(signalingUrl);
        document.getElementsByTagName("video")[0].play();

        setHasWebRTCStarted(true);
    };
    const handleKeyEvents = (event: KeyboardEvent) => {
        if (event.key.toLocaleLowerCase() === "p") {
            toggleIsPerformaceMenuOpen();
        }
        if (event.key.toLocaleLowerCase() === "m") {
            document.exitPointerLock();
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", handleKeyEvents, false);

        return () => {
            document.removeEventListener("keydown", handleKeyEvents, false);
        };
    });

    useEffect(() => {
        if (hasWebRTCStarted) {
            initWebRtc(signalingUrl);
        }
    }, [signalingUrl, hasWebRTCStarted]);

    return (
        <div>
            {!hasWebRTCStarted && (
                <button id="start" onClick={startWebRTC}>
                    Start
                </button>
            )}
            <div>
                {hasWebRTCStarted && isPlayer3d && (
                    <div className="threecontainer">
                        <DDDPlayer />
                    </div>
                )}
                <div
                    className="overlay"
                    onMouseDown={(e) => {
                        e.stopPropagation();
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    onMouseUp={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {hasWebRTCStarted && isPerformaceMenuOpen && <Statistics />}
                    <Settings hidden={hasWebRTCStarted && !isPointerLocked} />
                </div>
                {showInputOverlay && <InputOverlay />}
                <VideoPlayer hasWebRTCStarted={hasWebRTCStarted} />
            </div>
        </div>
    );
};

export default App;
ChartJS.register(...registerables);
