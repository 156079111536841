import { useFrame } from "@react-three/fiber";
import { useCallback, useEffect, useRef } from "react";
import { MathUtils, PerspectiveCamera, Vector3 } from "three";
import { MousePayload } from "../components/settings/Benchmark";
import { useStore } from "../utils/zustand";
import { handleCameraRotation } from "../webrtc/handleInputs";

let lon = 0;
let lat = 0;
export const sensitivity = 0.1;
const Camera = () => {
    const toggleIsPointerLocked = useStore(
        (state) => state.toggleIsPointerLocked
    );
    const isPointerLocked = useStore((state) => state.isPointerLocked);

    const camera = useRef<PerspectiveCamera>(null);

    const onPointerLockChange = useCallback(
        (e: Event) => {
            console.log("pointer");

            toggleIsPointerLocked();
        },
        [toggleIsPointerLocked]
    );
    const onCanvasClicked = useCallback(
        (e: Event) => {
            if (!isPointerLocked) {
                document.getElementsByTagName("canvas")[0].requestPointerLock();
            }
        },
        [isPointerLocked]
    );
    const onDocumentMouseMove = useCallback(
        (event: any) => {
            if (isPointerLocked) {
                const movementX = event.movementX || event.mozMovementX || 0;
                const movementY = event.movementY || event.mozMovementY || 0;

                lon = lon + movementX * sensitivity;
                lon = (360 + (lon % 360)) % 360;
                lat = lat + movementY * sensitivity * -1;
                lat = Math.max(-85, Math.min(85, lat));

                handleCameraRotation(lat / 360, lon / 360);
            }
        },
        [isPointerLocked]
    );
    const onBenchMarkEvent = (event: any) => {
        const ev = event as CustomEvent<MousePayload>;
        const movementX = ev.detail.x || 0;
        const movementY = ev.detail.y || 0;

        lon = lon + movementX * sensitivity;
        lon = (360 + (lon % 360)) % 360;
        lat = lat + movementY * sensitivity * -1;
        lat = Math.max(-85, Math.min(85, lat));

        handleCameraRotation(lat / 360, lon / 360);
    };
    useEffect(() => {
        document.addEventListener("mousemove", onDocumentMouseMove, false);
        document.addEventListener(
            "benchmarkMouseMovement",
            onBenchMarkEvent,
            false
        );
        document.getElementsByTagName("canvas")[0].onclick = onCanvasClicked;
        document.addEventListener(
            "pointerlockchange",
            onPointerLockChange,
            false
        );

        return () => {
            document.removeEventListener(
                "mousemove",
                onDocumentMouseMove,
                false
            );
            document.removeEventListener(
                "pointerlockchange",
                onPointerLockChange,
                false
            );
            document.removeEventListener(
                "benchmarkMouseMovement",
                onBenchMarkEvent,
                false
            );
        };
    }, [onCanvasClicked, onDocumentMouseMove, onPointerLockChange]);

    useFrame((state, delta) => {
        if (camera.current) {
            let phi = MathUtils.degToRad(90 - lat);
            let theta = MathUtils.degToRad(lon);

            let x = 500 * Math.sin(phi) * Math.cos(theta);
            let y = 500 * Math.cos(phi);
            let z = 500 * Math.sin(phi) * Math.sin(theta);

            state.camera.lookAt(new Vector3(x, y, z));
        }
    });
    return (
        <perspectiveCamera
            ref={camera}
            position={new Vector3(0, 0, 0)}
            args={[75, window.innerWidth / window.innerHeight, 1, 1100]}
        />
    );
};
export default Camera;
