import { useEffect, useMemo, useState } from "react";
import { KeyboardEvents } from "../webrtc/handleInputs";
import { KeyboardPayload, MousePayload } from "./settings/Benchmark";

const InputOverlay = () => {
    const [pressedKeys, setPressedKeys] = useState<number[]>([]);
    const [mouseRotation, setMouseRotation] = useState<MousePayload[]>([]);
    const handleBenchmarkKeyEvenet = (event: any) => {
        const ev = event as CustomEvent<KeyboardPayload>;
        const key = ev.detail.keycode;

        if (ev.detail.type === KeyboardEvents.KeyDown) {
            if (!event.repeat) {
                setPressedKeys((old) => [...old, key]);
            }
            // TextEvent
        } else if (ev.detail.type === KeyboardEvents.KeyUp) {
            setPressedKeys((old) => {
                const index = old.indexOf(key) ?? -1;
                if (index > -1) {
                    // only splice array when item is found
                    old.splice(index, 1); // 2nd parameter means remove one item only
                }
                return [...old];
            });
        }
    };
    const handleKeyEvents = (event: KeyboardEvent) => {
        const key = event.keyCode;

        if (event.type === "keydown") {
            if (!event.repeat) {
                setPressedKeys((old) => [...old, key]);
            }
            // TextEvent
        } else if (event.type === "keyup") {
            setPressedKeys((old) => {
                const index = old.indexOf(key) ?? -1;
                if (index > -1) {
                    // only splice array when item is found
                    old.splice(index, 1); // 2nd parameter means remove one item only
                }
                return [...old];
            });
        }
    };
    const onMouseMoveEvent = (event: any) => {
        const ev = event as CustomEvent<MousePayload>;
        setMouseRotation((old) => {
            if (old.length >= 10) {
                old.shift();
            }
            return [...old, { x: ev.detail.x, y: ev.detail.y }];
        });
    };
    const onDocumentMouseMove = (event: any) => {
        const movementX = event.movementX || event.mozMovementX || 0;
        const movementY = event.movementY || event.mozMovementY || 0;

        //const newEntry  = Math.atan2(movementY, movementX) + Math.PI / 2
        setMouseRotation((old) => {
            if (old.length >= 10) {
                old.shift();
            }
            return [...old, { x: movementX, y: movementY }];
        });
    };
    useEffect(() => {
        document.addEventListener(
            "benchmarkKeyboard",
            handleBenchmarkKeyEvenet,
            false
        );
        document.addEventListener(
            "benchmarkMouseMovement",
            onMouseMoveEvent,
            false
        );
        document.addEventListener("mousemove", onDocumentMouseMove, false);
        document.addEventListener("keyup", handleKeyEvents, false);
        document.addEventListener("keydown", handleKeyEvents, false);
        return () => {
            document.removeEventListener(
                "benchmarkKeyboard",
                handleBenchmarkKeyEvenet,
                false
            );
            document.removeEventListener(
                "benchmarkMouseMovement",
                onMouseMoveEvent,
                false
            );
            document.removeEventListener(
                "mousemove",
                onDocumentMouseMove,
                false
            );
        };
    }, []);
    useEffect(() => {
        const timeoutId = window.setTimeout(() => {
            setMouseRotation([]);
        }, 500);
        return () => {
            window.clearTimeout(timeoutId);
        };
    }, [mouseRotation]);

    const accumulated = useMemo(
        () =>
            mouseRotation.reduce(
                (prev, item) => {
                    return { x: prev.x + item.x, y: prev.y + item.y };
                },
                { x: 0, y: 0 }
            ),
        [mouseRotation]
    );

    return (
        <div className="inputOverlay">
            <div className="buttonOverlay">
                <div
                    className={
                        "Wkey" + (pressedKeys.includes(87) ? " activeKey" : "")
                    }
                >
                    W
                </div>
                <div
                    className={
                        "Akey" + (pressedKeys.includes(65) ? " activeKey" : "")
                    }
                >
                    A
                </div>
                <div
                    className={
                        "Skey" + (pressedKeys.includes(83) ? " activeKey" : "")
                    }
                >
                    S
                </div>
                <div
                    className={
                        "Dkey" + (pressedKeys.includes(68) ? " activeKey" : "")
                    }
                >
                    D
                </div>
                <div
                    className={
                        "Spacekey" +
                        (pressedKeys.includes(32) ? " activeKey" : "")
                    }
                >
                    Space
                </div>
            </div>
            <div
                className="mouseOverlay"
                style={{
                    transform:
                        "rotate(" +
                        (Math.atan2(accumulated.y, accumulated.x) +
                            Math.PI / 2) +
                        "rad)",
                }}
            >
                {mouseRotation.length === 0 ? "" : "↑"}
            </div>
        </div>
    );
};
export default InputOverlay;
