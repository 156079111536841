import { useEffect, useState } from "react";
import { useStore } from "../../utils/zustand";
import { initWebRtc } from "../../webrtc/init";

const ConnectionSettings = () => {
    const settings = useStore((state) => state.settings.connection);
    const changeSettings = useStore((state) => state.setSettingsAttribute);

    const [url, setUrl] = useState<string>(settings.signalingUrl);

    useEffect(() => {
        setUrl(settings.signalingUrl);
    }, [settings.signalingUrl, setUrl]);
    return (
        <div className="connectionSettings">
            <h2>Connection</h2>
            <div>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        changeSettings("connection", "signalingUrl", url);
                    }}
                >
                    <input
                        type={"text"}
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                    <input type="submit" value={"Apply"} />
                </form>
            </div>
            <button
                onClick={() => {
                    initWebRtc(settings.signalingUrl);
                }}
            >
                Reconnect
            </button>
        </div>
    );
};
export default ConnectionSettings;
