import { useCallback, useEffect, useRef } from "react";
import { useStore } from "../utils/zustand";
import { handleCameraRotation } from "../webrtc/handleInputs";

interface VideoPlayerProps {
    hasWebRTCStarted: boolean;
}
let lon = 0;
let lat = 0;
const sensitivity = 0.1;
const VideoPlayer = (props: VideoPlayerProps) => {
    const isPlayer3d = useStore(
        (state) => state.settings.videoplayer.isPlayer3d
    );
    const toggleIsPointerLocked = useStore(
        (state) => state.toggleIsPointerLocked
    );
    const isPointerLocked = useStore((state) => state.isPointerLocked);
    const player = useRef<HTMLVideoElement>(null);

    const onPointerLockChange = useCallback(
        (e: Event) => {
            toggleIsPointerLocked();
        },
        [toggleIsPointerLocked]
    );
    const onCanvasClicked = useCallback(
        (e: Event) => {
            if (!isPointerLocked) {
                player.current?.requestPointerLock();
            }
        },
        [isPointerLocked]
    );
    const onDocumentMouseMove = useCallback(
        (event: any) => {
            if (isPointerLocked) {
                const movementX = event.movementX || event.mozMovementX || 0;
                const movementY = event.movementY || event.mozMovementY || 0;

                lon = lon + movementX * sensitivity;
                lon = (360 + (lon % 360)) % 360;
                lat = lat + movementY * sensitivity * -1;
                lat = Math.max(-85, Math.min(85, lat));

                handleCameraRotation(lat / 360, lon / 360);
            }
        },
        [isPointerLocked]
    );
    useEffect(() => {
        if (!isPlayer3d) {
            document.addEventListener("mousemove", onDocumentMouseMove, false);
            if (player.current) player.current.onclick = onCanvasClicked;
            document.addEventListener(
                "pointerlockchange",
                onPointerLockChange,
                false
            );
        }

        return () => {
            if (!isPlayer3d) {
                document.removeEventListener(
                    "mousemove",
                    onDocumentMouseMove,
                    false
                );
                document.removeEventListener(
                    "pointerlockchange",
                    onPointerLockChange,
                    false
                );
            }
        };
    }, [onCanvasClicked, onDocumentMouseMove, onPointerLockChange, isPlayer3d]);
    return (
        <video
            ref={player}
            id="video"
            className={
                !props.hasWebRTCStarted || isPlayer3d ? "" : "videoactive"
            }
        ></video>
    );
};
export default VideoPlayer;
