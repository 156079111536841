import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import {
    BackSide,
    Euler,
    sRGBEncoding,
    TextureLoader,
    VideoTexture,
} from "three";
import Camera from "./Camera";
import ThreeStats from "./ThreeStats";

const DDDPlayer = () => {
    const videoSource = document.getElementsByTagName("video")[0];

    return (
        <Canvas>
            <Camera />
            <ThreeStats />
            <mesh scale={[-1, 1, 1]} rotation={new Euler(0, Math.PI, 0)}>
                <sphereBufferGeometry args={[500, 60, 40]} />
                <meshBasicMaterial side={BackSide} toneMapped={false}>
                    <videoTexture
                        attach="map"
                        args={[videoSource]}
                        encoding={sRGBEncoding}
                    />
                </meshBasicMaterial>
            </mesh>
        </Canvas>
    );
};
export default DDDPlayer;
