import { useStore } from "../../utils/zustand";

const StatisticsSettings = () => {
    const settings = useStore((state) => state.settings);
    const changeSettings = useStore((state) => state.setSettingsAttribute);

    return (
        <div className="statisticsSettings">
            <h2>Statistics</h2>
            <div>
                <div>
                    <input
                        type="checkbox"
                        id="ping"
                        onChange={(e) =>
                            changeSettings(
                                "statistics",
                                "ping",
                                e.target.checked
                            )
                        }
                        checked={settings.statistics.ping}
                    />
                    <label htmlFor="ping">Ping (ms)</label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="bitrate"
                        onChange={(e) =>
                            changeSettings(
                                "statistics",
                                "bitrate",
                                e.target.checked
                            )
                        }
                        checked={settings.statistics.bitrate}
                    />
                    <label htmlFor="bitrate">Bitrate (kbit/s)</label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="averageDecodingTime"
                        onChange={(e) =>
                            changeSettings(
                                "statistics",
                                "averageDecodingTime",
                                e.target.checked
                            )
                        }
                        checked={settings.statistics.averageDecodingTime}
                    />
                    <label htmlFor="averageDecodingTime">
                        Average Decoding Time (ms)
                    </label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="averageProcessingTime"
                        onChange={(e) =>
                            changeSettings(
                                "statistics",
                                "averageProcessingTime",
                                e.target.checked
                            )
                        }
                        checked={settings.statistics.averageProcessingTime}
                    />
                    <label htmlFor="averageProcessingTime">
                        Average Processing Time (ms)
                    </label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="fpsStream"
                        onChange={(e) =>
                            changeSettings(
                                "statistics",
                                "fpsStream",
                                e.target.checked
                            )
                        }
                        checked={settings.statistics.fpsStream}
                    />
                    <label htmlFor="fpsStream">FPS (Stream)</label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="decodedFps"
                        onChange={(e) =>
                            changeSettings(
                                "statistics",
                                "decodedFps",
                                e.target.checked
                            )
                        }
                        checked={settings.statistics.decodedFps}
                    />
                    <label htmlFor="decodedFps">
                        Decoded Frames per Second
                    </label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="fpsBrowser"
                        onChange={(e) =>
                            changeSettings(
                                "statistics",
                                "fpsBrowser",
                                e.target.checked
                            )
                        }
                        checked={settings.statistics.fpsBrowser}
                    />
                    <label htmlFor="fpsBrowser">FPS (Browser)</label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="avgFrametimeBrowser"
                        onChange={(e) =>
                            changeSettings(
                                "statistics",
                                "avgFrametimeBrowser",
                                e.target.checked
                            )
                        }
                        checked={settings.statistics.avgFrametimeBrowser}
                    />
                    <label htmlFor="avgFrametimeBrowser">
                        Average Frametime (ms) (Browser)
                    </label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="maxFrametimeBrowser"
                        onChange={(e) =>
                            changeSettings(
                                "statistics",
                                "maxFrametimeBrowser",
                                e.target.checked
                            )
                        }
                        checked={settings.statistics.maxFrametimeBrowser}
                    />
                    <label htmlFor="maxFrametimeBrowser">
                        Maximum Frametime (ms) (Browser)
                    </label>
                </div>
            </div>
        </div>
    );
};
export default StatisticsSettings;
